import { useState } from 'react';
import 'react-tabs/style/react-tabs.css';
import { Tabs, Tab } from 'react-bootstrap-tabs';
import { Audio } from 'react-loader-spinner'
function App() {
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [googleResults, setgoogleResults] = useState([]);
  const [bingResults, setbingResults] = useState([]);
  const [wipoResults, setwipoResults] = useState([]);
  const [saipResults, setsaipResults] = useState([]);
  const [duckduckgoResults, setduckduckgoResults] = useState([]);

  const [searchInfo, setSearchInfo] = useState({});
  const handleSearch = async e => {
    e.preventDefault();
    setLoading(true)
    if (search === '') return;
    console.log("slkdflsdlfksldkf")


    const endpoint = `https://en.wikipedia.org/w/api.php?action=query&list=search&prop=info&inprop=url&utf8=&format=json&origin=*&srsearch=${search}`
    const google = `https://www.googleapis.com/customsearch/v1?q=براءة اختراع\ ${search}&key=AIzaSyBC6S9CGJGurIw49Twwnot9ZfUgU-r2_hg&cx=000950167190857528722:vf0rypkbf0w`
    const duckduckgo = `https://www.googleapis.com/customsearch/v1?q=براءة اختراع\ ${search}\ duckduckgo&key=AIzaSyCo7okPRwVPzLSlmYhZXsW3Zz11TrtYueY&cx=000950167190857528722:vf0rypkbf0w`
    const bing = `https://www.googleapis.com/customsearch/v1?q=براءة اختراع\ ${search}\ bing&key=AIzaSyBexWro6lOuVcfmR5uSFvd-jVY8uee45OA&cx=000950167190857528722:vf0rypkbf0w`
    const wipo = `https://www.googleapis.com/customsearch/v1?q=براءة اختراع\ ${search}\  wipo&key=AIzaSyC0c8DnMCp3OYm4Rmwramp2nDcK828Rme4&cx=000950167190857528722:vf0rypkbf0w`
    const saip = `https://www.googleapis.com/customsearch/v1?q=براءة اختراع\ ${search}\ saip&key=AIzaSyBexWro6lOuVcfmR5uSFvd-jVY8uee45OA&cx=000950167190857528722:vf0rypkbf0w`

    const response = await fetch(endpoint);
    const googleResponse = await fetch(google);
    const duckduckgoResponse = await fetch(duckduckgo);
    const bingResponse = await fetch(bing);
    const saipResponse = await fetch(saip);
    const wipoResponse = await fetch(wipo);

    console.log(response)
    if (!response.ok) {
      throw Error(response.statusText);
    }

    const json = await response.json();
    const googleJson = await googleResponse.json();
    const duckduckgoJson = await duckduckgoResponse.json();
    const bingjson  = await bingResponse.json();
    const saipjson  = await saipResponse.json();
    const wipojson  = await wipoResponse.json();

const imagelogo = `https://d1hbpr09pwz0sk.cloudfront.net/logo_url/saip-saudi-authority-for-intellectual-property-d2b1c84d`
    console.log(json);
    // console.log("duckduckgoJson", duckduckgoJson);
    // console.log("wiki", json);
 
    console.log("googleJson.items ", googleJson.items)
    if (googleJson.items)
      setgoogleResults(googleJson.items)
    setduckduckgoResults(duckduckgoJson.items);
    setResults(json.query.search);
    setbingResults(bingjson.items);
    setwipoResults(wipojson.items);
    setsaipResults(saipjson.items);
    setSearchInfo(json.query.searchinfo);

    setLoading(false)
  }
  if (loading) {
    return (
      <div className='loading'>
       <img 
      src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b3/Wikipedia-logo-v2-en.svg/1200px-Wikipedia-logo-v2-en.svg.png"
      alt="new"
      style={{
       
        height:"70px",
        width:"12%",
        borderRadius: "4px 4px 4px 4px"
       
      }}
      />
      <img 
      src="https://s3.ap-southeast-1.amazonaws.com/images.deccanchronicle.com/dc-Cover-30tgm3v8oltscotbsp7vvp2881-20190728002902.Medi.jpeg"
      alt="new"
      style={{
       
        height:"70px",
        width:"12%",
        borderRadius: "4px 4px 4px 4px"
      }}
      />
       <img 
      src="https://cdn.searchenginejournal.com/wp-content/uploads/2017/02/Untitled-design-48.png"
      alt="new"
      style={{
       
        height:"70px",
        width:"12%",
        borderRadius: "4px 4px 4px 4px"
      }}
      />
       <img 
      src="https://www.itu.int/net4/wsis/ungis/Content/img/logos/uniform/wipo.png"
      alt="new"
      style={{
       
        height:"70px",
        width:"12%",
        borderRadius: "4px 4px 4px 4px"
      }}
      />
     
        <img 
      src="https://www.arabnews.com/sites/default/files/2022/01/27/3038311-573447961.png"
      alt="new"
      style={{
       
        height:"70px",
        width:"12%",
        borderRadius: "4px 4px 4px 4px"
       
      }}
      />
      </div>

    )
  }

  return (
    <div className="App">
      <header>
        <h1>Eiipo <strong>Search</strong></h1>
        <form className="search-box" onSubmit={handleSearch.bind(this)}>
          <input
            type="search"
            placeholder="what are you looking for?.."
            value={search}
            key={['search', search[0], 0].join('_')}
            onChange={e => setSearch(e.target.value)}
            autoFocus
          >

          </input>
        </form>
        {/* <button 
        
          onClick={e => setSearch(e.target.value)}
        string ="search" value ="slkdfl" style={{height:40, width:90 , background:"#d32a2a" , color:'white' , border:"40"}} >Search</button> */}

      </header>
     
      {results.length != 0 || googleResults != 0   || duckduckgoResults != 0 ?
        <Tabs onSelect={(index, label) => console.log(label + ' selected')}>
          <Tab label="Wiki">{(searchInfo.totalhits) ? <p>عدد مرات البحث  :  {searchInfo.totalhits}</p> : ''}
            <h2> <div className="results">
              {results.length != 0 ? results.map((result, i) => {
                const url = `https://en.wikipedia.org/?curid=${result.pageid}`;
                return (
                  <div className="result" key={i}>
                    <h3>{result.title}</h3>
                    <p dangerouslySetInnerHTML={{ __html: result.snippet }}></p>
                    <a href={url} target="_blank" rel='nofollow'>Read More</a>
                  </div>
                )
              }) : (<div> no result in wiki </div>)}


            </div></h2></Tab>
          <Tab label="Google"> <h2> <div className="results">
            {googleResults.length != 0 ? googleResults.map((result, i) => {
              const url = result.link;
              return (
                <div className="result" key={i}>
                  <h3>{result.title}</h3>
                  <p dangerouslySetInnerHTML={{ __html: result.snippet }}></p>
                  <a href={url} target="_blank" rel='nofollow'>Read More</a>
                </div>
              )
            }) : (<div> no result in google </div>)}


          </div></h2></Tab>
          <Tab label="goDuckDuck"> <h2> <div className="results">
            {duckduckgoResults.length != 0 ? duckduckgoResults.map((result, i) => {
              const url = result.link;
              return (
                <div className="result" key={i}>
                  <h3>{result.title}</h3>
                  <p dangerouslySetInnerHTML={{ __html: result.snippet }}></p>
                  <a href={url} target="_blank" rel='nofollow'>Read More</a>
                </div>
              )
            }) : (<div> no result in duckduckgo </div>)}


          </div></h2></Tab>
           <Tab label="bing"> <h2> <div className="results">
            {bingResults.length != 0 ? bingResults.map((result, i) => {
              const url = result.link;
              return (
                <div className="result" key={i}>
                  <h3>{result.title}</h3>
                  <p dangerouslySetInnerHTML={{ __html: result.snippet }}></p>
                  <a href={url} target="_blank" rel='nofollow'>Read More</a>
                </div>
              )
            }) : (<div> no result in bing </div>)}


          </div></h2></Tab>
          <Tab label="wipo"> <h2> <div className="results">
            {wipoResults.length != 0 ? wipoResults.map((result, i) => {
              const url = result.link;
              return (
                <div className="result" key={i}>
                  <h3>{result.title}</h3>
                  <p dangerouslySetInnerHTML={{ __html: result.snippet }}></p>
                  <a href={url} target="_blank" rel='nofollow'>Read More</a>
                </div>
              )
            }) : (<div> no result in Wipo </div>)}


          </div></h2></Tab>
          <Tab label="الهئة السعودية"> <h2> <div className="results">
            {saipResults.length != 0 ? saipResults.map((result, i) => {
              const url = result.link;
              return (
                <div className="result" key={i}>
                  <h3>{result.title}</h3>
                  <p dangerouslySetInnerHTML={{ __html: result.snippet }}></p>
                  <a href={url} target="_blank" rel='nofollow'>Read More</a>
                </div>
              )
            }) : (<div> no result in Saip </div>)}


          </div></h2></Tab>
          
        </Tabs>
        
        : <h1></h1>
      }



    </div>
  );
}

export default App;
